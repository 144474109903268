<template>
  <el-main class="rh-form">
    <form-fpregradedit :routetolist="{ name: 'fpregrads' }" />
  </el-main>
</template>
<script>
import FormFpregradedit from "@/components/rh/formulaires/FormFpregradedit";

export default {
  components: { "form-fpregradedit": FormFpregradedit },
};
</script>
